export default function getAsset(assetArr, index, prevDivisor, prevLength) {
  return assetArr[
    calcAssetIndex(index, assetArr.length, prevDivisor, prevLength)
  ];
}

function calcAssetIndex(index, currLength, prevDivisor, prevLength) {
  let calc, divisor;

  if (!prevDivisor) {
    calc = index % currLength;
  } else {
    divisor = prevDivisor * prevLength;
    calc = (index / divisor) % currLength;
  }
  return Math.floor(calc);
}
