export default function assetPaths(permutationObj) {
  return [
    {
      assetType: "skinColor",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/skinColor/${permutationObj.skinColor}.png`,
    },
    {
      assetType: "tattoos",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/tattoos/ivory/${permutationObj.tattoos}.png`,
    },
    {
      assetType: "armorFoot",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/armorFoot/${permutationObj.armorFoot}.png`,
    },
    {
      assetType: "armorHand",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/armorHand/${permutationObj.armorHand}.png`,
    },
    {
      assetType: "armorLeg",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/armorLeg/${permutationObj.armorLeg}.png`,
    },
    {
      assetType: "armorTorso",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/armorTorso/${permutationObj.armorTorso}.png`,
    },
    {
      assetType: "headShape",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/headShape/${permutationObj.headShape}.png`,
    },
    {
      assetType: "ears",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/ears/ivory/${permutationObj.ears}.png`,
    },
    {
      assetType: "mouth",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/mouth/ivory/${permutationObj.mouth}.png`,
    },
    {
      assetType: "eyes",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/eyeColor/${permutationObj.eyeColor}.png`,
    },
    {
      assetType: "eyeShape",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/eyeShape/${permutationObj.eyeShape}.png`,
    },
    {
      assetType: "nose",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/nose/ivory/${permutationObj.nose}.png`,
    },
    {
      assetType: "scars",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/scars/${permutationObj.scars}.png`,
    },
    {
      assetType: "accessories",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/accessories/${permutationObj.accessories}.png`,
    },
    {
      assetType: "beard",
      path: `./assets/male/${permutationObj.bodyShape}/beard/${permutationObj.hairColor}/${permutationObj.beard}.png`,
    },
    {
      assetType: "hair",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/hair/${permutationObj.hairColor}/${permutationObj.hair}.png`,
    },
    {
      assetType: "armorHead",
      path: `./assets/${permutationObj.sex}/${permutationObj.bodyShape}/armorHead/${permutationObj.armorHead}.png`,
    },
  ];
}
