<script>
  import { assetLockState } from "../stores.js";
  export let assetType, asset, pm;

  function formatTitle(string) {
    let output = "";

    let wordArr = (string.charAt(0).toUpperCase() + string.slice(1)).match(
      /[A-Z][a-z]+/g
    );

    wordArr.forEach((word) => (output += `${word} `));

    return output.trim();
  }
</script>

<div class="detail detail-container">
  <div class="text-group">
    {#if pm.sex === "female" && assetType === "beard"}
      <div class="asset-type o60">{formatTitle(assetType)}</div>
      <div class="asset-name monospace line-through o60">{asset}</div>
    {:else if asset === null}
      <div class="asset-type o60">{formatTitle(assetType)}</div>
      <div class="asset-name monospace red">{asset}</div>
    {:else}
      <div class="asset-type o60">{formatTitle(assetType)}</div>
      <div class="asset-name monospace">{asset}</div>
    {/if}
  </div>
  {#if assetType === $assetLockState.assetType && $assetLockState.asset !== "empty"}
    <div class="lock">
      <svg
        fill="none"
        height="28"
        viewBox="0 0 14 28"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        ><path
          clip-rule="evenodd"
          d="m4.5 9.5c0-1.38071 1.11929-2.5 2.5-2.5s2.5 1.11929 2.5 2.5v1.5h-5zm-2 1.5v-1.5c0-2.48528 2.01472-4.5 4.5-4.5s4.5 2.01472 4.5 4.5v1.5h1.25c.4142 0 .75.3358.75.75v8.5c0 .4142-.3358.75-.75.75h-11.5c-.414214 0-.75-.3358-.75-.75v-8.5c0-.4142.335786-.75.75-.75z"
          fill="#ececec"
          fill-rule="evenodd"
        /></svg
      >
    </div>
  {/if}
</div>

<style>
  .detail-container {
    display: flex;
    align-items: center;
  }

  .asset-type {
    font-weight: bold;
  }

  .asset-name {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 700;
  }

  .lock {
    margin-left: 8px;
    display: flex;
    align-items: center;
    opacity: 0.6;
  }

  .line-through {
    text-decoration: line-through;
  }

  .o60 {
    opacity: 0.6;
  }

  .red {
    color: #db5f57;
  }
</style>
