<script>
  import assetIndex from "../../meta_data/asset-index.json";
  import { assetLockState } from "../stores.js";

  let lockIsEnabled, assetsArr;
  let referenceObj = assetIndex.male.bodyShape.bulky;
  let assetTypeSelected = "disabled";
  let assetSelected = "empty";

  let assetTypeArr = [];
  for (const key in referenceObj) {
    assetTypeArr.push(key);
  }

  $: lockIsEnabled = assetTypeSelected !== "disabled";
  $: assetIsSelected = assetSelected !== "empty";

  $: {
    if (referenceObj[assetTypeSelected] === undefined) {
      assetsArr = [];
    } else {
      assetsArr = referenceObj[assetTypeSelected];
    }
  }

  $: {
    $assetLockState = {
      assetType: assetTypeSelected,
      asset: assetSelected,
    };
  }

  function handleChange() {
    assetSelected = "empty";
  }
</script>

<div
  class="lock-group {lockIsEnabled ? '' : 'disabled'} {assetIsSelected
    ? ''
    : 'asset-empty'}"
>
  {#if lockIsEnabled && assetIsSelected}
    <svg
      fill="none"
      height="28"
      viewBox="0 0 14 28"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
      ><path
        clip-rule="evenodd"
        d="m4.5 9.5c0-1.38071 1.11929-2.5 2.5-2.5s2.5 1.11929 2.5 2.5v1.5h-5zm-2 1.5v-1.5c0-2.48528 2.01472-4.5 4.5-4.5s4.5 2.01472 4.5 4.5v1.5h1.25c.4142 0 .75.3358.75.75v8.5c0 .4142-.3358.75-.75.75h-11.5c-.414214 0-.75-.3358-.75-.75v-8.5c0-.4142.335786-.75.75-.75z"
        fill="#ececec"
        fill-rule="evenodd"
      /></svg
    >
  {:else}
    <svg
      fill="none"
      height="28"
      viewBox="0 0 14 28"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
      ><path
        clip-rule="evenodd"
        d="m4.5 8.5c0-1.38071 1.11929-2.5 2.5-2.5s2.5 1.11929 2.5 2.5v1.5h2v-1.5c0-2.48528-2.01472-4.5-4.5-4.5s-4.5 2.01472-4.5 4.5v3.5h-1.25c-.414214 0-.75.3358-.75.75v8.5c0 .4142.335786.75.75.75h11.5c.4142 0 .75-.3358.75-.75v-8.5c0-.4142-.3358-.75-.75-.75h-8.25z"
        fill="#ececec"
        fill-rule="evenodd"
      /></svg
    >
  {/if}
  <div class="text">Locking</div>
  <select
    bind:value={assetTypeSelected}
    on:change={handleChange}
    on:blur={handleChange}
    name="asset-type"
    id="select-asset-type"
  >
    <option value="disabled">disabled</option>
    {#each assetTypeArr as assetType}
      {#if assetType !== "sex"}
        <option value={assetType}>{assetType}</option>
      {/if}
    {/each}
  </select>
  {#if lockIsEnabled}
    <div class="text">to</div>
    <select bind:value={assetSelected} name="asset" id="select-asset">
      <option value="empty">select asset…</option>
      {#each assetsArr as asset}
        <option value={asset}>{asset}</option>
      {/each}
    </select>
  {/if}
</div>

<style>
  /* Reset */
  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color: inherit;
  }

  .lock-group,
  select {
    font-size: 16px;
  }

  .lock-group {
    display: flex;
    align-items: center;
  }

  .lock-group > svg {
    margin-right: 8px;
  }

  .lock-group.disabled > svg {
    opacity: 0.6;
  }

  select {
    box-shadow: inset 0 -2px 0 rgba(255, 255, 255, 1);
    margin: 0 4px;
    height: 28px;
  }

  .lock-group.disabled select,
  .lock-group.asset-empty select:last-of-type {
    opacity: 0.6;
  }

  select:last-of-type {
    margin-right: 0;
  }

  option {
    background: #fff;
    color: #151515;
  }
</style>
