<script>
  import PermutationDisplay from "./PermutationDisplay.svelte";
  import AssetLock from "./AssetLock.svelte";
  import metaData from "../../meta_data/meta-data.json";
  import polyphaser from "../helpers/polyphaser.js";
  import getRandomComparison from "../helpers/get-random-comparison";
  import { assetLockState } from "../stores.js";

  let lastBuildDate, lastBuildDateFormatted, comparison;
  let history = [];
  let historyCurrentIndex = -1;
  let currentIndices = [];
  let spacePressed = false;
  let arrowLeftPressed = false;
  let arrowRightPressed = false;
  let possibilitySpaceSize = polyphaser.calcPossibilitySpaceSize();

  $: {
    if (
      $assetLockState.assetType !== undefined &&
      $assetLockState.assetType !== "disabled" &&
      $assetLockState.asset !== "empty"
    ) {
      populateIndices();
    }
  }

  $: stateIsUndoable = historyCurrentIndex > 0;
  $: stateIsRedoable = historyCurrentIndex < history.length - 1;

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  $: {
    lastBuildDate = new Date(metaData.buildDate);
    lastBuildDateFormatted = `${
      dayNames[lastBuildDate.getDay()]
    } ${lastBuildDate.getDate()} ${
      monthNames[lastBuildDate.getMonth()]
    } ${lastBuildDate.getFullYear()}, ${lastBuildDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${lastBuildDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  }

  populateIndices();

  function populateIndices() {
    let randomIndices = [];

    for (let index = 0; index < 3; index++) {
      if (
        $assetLockState.asset !== "empty" &&
        $assetLockState.assetType !== "disabled"
      ) {
        randomIndices[index] = polyphaser.getRandomIndexLock($assetLockState);
      } else {
        randomIndices[index] = polyphaser.getRandomIndex();
      }
    }

    if (historyCurrentIndex !== history.length - 1) {
      history.length = historyCurrentIndex + 1;
    }

    history.push(randomIndices);
    historyCurrentIndex++;
    currentIndices = history[historyCurrentIndex];

    comparison = getRandomComparison(polyphaser.calcPossibilitySpaceSize());
  }

  function undo() {
    if (stateIsUndoable) {
      historyCurrentIndex--;
      currentIndices = history[historyCurrentIndex];
    }
  }

  function redo() {
    if (stateIsRedoable) {
      historyCurrentIndex++;
      currentIndices = history[historyCurrentIndex];
    }
  }

  function handleKeydown(event) {
    if (event.key === " ") {
      event.preventDefault();
      spacePressed = true;
    }

    if (event.key === "ArrowLeft") {
      event.preventDefault();
      arrowLeftPressed = true;
    }

    if (event.key === "ArrowRight") {
      event.preventDefault();
      arrowRightPressed = true;
    }
  }

  function handleKeyup(event) {
    if (event.key === " ") {
      spacePressed = false;
      populateIndices();
    }

    if (event.key === "ArrowLeft") {
      arrowLeftPressed = false;
      undo();
    }

    if (event.key === "ArrowRight") {
      arrowRightPressed = false;
      redo();
    }
  }
</script>

<svelte:window on:keydown={handleKeydown} on:keyup={handleKeyup} />

<main>
  <header class="topbar">
    <div class="title-group">
      <div class="title">
        <strong>Scarlet Republics</strong> 2D Character Generator
      </div>
      <div class="datestamp">
        <div class="heading">Build Date:</div>
        <div class="value">{lastBuildDateFormatted}</div>
      </div>
      <div class="possibility-space">
        <div class="heading">Possibility Space:</div>
        <div class="value">
          {possibilitySpaceSize.toLocaleString("en")}
          <span class="comparison">({comparison})</span>
        </div>
      </div>
    </div>
    <div class="button-group">
      <AssetLock />
      <div
        on:click={populateIndices}
        class="button-randomize {spacePressed ? 'pressed' : ''}"
      >
        <svg
          fill="none"
          height="18"
          viewBox="0 0 18 18"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            clip-rule="evenodd"
            d="m1.5 0c-.828427 0-1.5.671573-1.5 1.5v9c0 .8284.671573 1.5 1.5 1.5h3.5v-4.5c0-1.38071 1.11929-2.5 2.5-2.5h4.5v-3.5c0-.828427-.6716-1.5-1.5-1.5zm3 3c0 .55228-.44772 1-1 1s-1-.44772-1-1 .44772-1 1-1 1 .44772 1 1zm1.5 4.5c0-.82843.67157-1.5 1.5-1.5h9c.8284 0 1.5.67157 1.5 1.5v9c0 .8284-.6716 1.5-1.5 1.5h-9c-.82843 0-1.5-.6716-1.5-1.5zm4 1.5c0 .55228-.44772 1-1 1s-1-.44772-1-1 .44772-1 1-1 1 .44772 1 1zm5 1c.5523 0 1-.44772 1-1s-.4477-1-1-1-1 .44772-1 1 .4477 1 1 1zm-2 2c0 .5523-.4477 1-1 1s-1-.4477-1-1 .4477-1 1-1 1 .4477 1 1zm-4 4c.55228 0 1-.4477 1-1s-.44772-1-1-1-1 .4477-1 1 .44772 1 1 1zm7-1c0 .5523-.4477 1-1 1s-1-.4477-1-1 .4477-1 1-1 1 .4477 1 1z"
            fill="#151515"
            fill-rule="evenodd"
          /></svg
        >
        <span class="button-label">Randomize</span>
      </div>
      <div class="shortcut">(Space)</div>
      <div
        class="button-undo {stateIsUndoable ? 'active' : ''} {arrowLeftPressed
          ? 'pressed'
          : ''}"
        on:click={undo}
      >
        <svg
          fill="none"
          height="28"
          viewBox="0 0 28 28"
          width="28"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            clip-rule="evenodd"
            d="m13.1065 6.99583-2.7024 2.71848h4.9805c3.1069 0 5.6154 2.53199 5.6154 5.64289 0 3.1108-2.5085 5.6428-5.6154 5.6428h-3.7692v-2h3.7692c1.9912 0 3.6154-1.6253 3.6154-3.6428s-1.6242-3.6429-3.6154-3.6429h-4.9805l2.7024 2.7185-1.4184 1.41-5.09813-5.1285 5.09813-5.12848z"
            fill="#fff"
            fill-rule="evenodd"
          /></svg
        >
      </div>
      <div
        class="button-redo {stateIsRedoable ? 'active' : ''} {arrowRightPressed
          ? 'pressed'
          : ''}"
        on:click={redo}
      >
        <svg
          fill="none"
          height="28"
          viewBox="0 0 28 28"
          width="28"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            clip-rule="evenodd"
            d="m14.8935 6.99583 2.7024 2.71848h-4.9805c-3.1069 0-5.6154 2.53199-5.6154 5.64289 0 3.1108 2.5085 5.6428 5.6154 5.6428h3.7692v-2h-3.7692c-1.9912 0-3.6154-1.6253-3.6154-3.6428s1.6242-3.6429 3.6154-3.6429h4.9805l-2.7024 2.7185 1.4184 1.41 5.0981-5.1285-5.0981-5.12848z"
            fill="#fff"
            fill-rule="evenodd"
          /></svg
        >
      </div>
    </div>
  </header>
  <div class="permutation-displays">
    {#each currentIndices as permutationIndex}
      <PermutationDisplay {permutationIndex} />
    {/each}
  </div>
</main>

<style>
  .topbar {
    background-color: #151515;
    padding: 0 16px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
  }

  .title {
    font-size: 1.5em;
  }

  .title-group {
    display: flex;
    align-items: center;
  }

  .button-group {
    display: flex;
    align-items: center;
  }

  .button-undo {
    margin-left: 24px;
  }

  .button-redo {
    margin-left: 8px;
  }

  .button-undo,
  .button-redo {
    opacity: 0.16;
    border-radius: 3px;
    border: 2px solid #fff;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }

  .button-undo.active,
  .button-redo.active {
    opacity: 1;
    user-select: auto;
    cursor: pointer;
  }

  .button-undo.active:hover,
  .button-redo.active:hover {
    opacity: 0.8;
  }

  .button-undo.active.pressed,
  .button-redo.active.pressed,
  .button-undo.active:active,
  .button-redo.active:active {
    opacity: 0.6;
  }

  .heading {
    opacity: 0.6;
  }

  .shortcut,
  .datestamp,
  .possibility-space {
    font-weight: bold;
  }

  .datestamp,
  .possibility-space {
    margin-left: 24px;
  }

  .comparison {
    opacity: 0.6;
  }

  .shortcut {
    opacity: 0.6;
    margin-left: 8px;
  }

  .button-randomize {
    background: #fff;
    color: #151515;
    border-radius: 3px;
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 12px;
    user-select: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-left: 24px;
  }

  .button-label {
    margin-left: 8px;
  }

  .button-randomize:hover {
    opacity: 0.8;
  }

  .button-randomize.pressed,
  .button-randomize:active {
    opacity: 0.6;
  }

  .permutation-displays {
    display: flex;
  }
</style>
