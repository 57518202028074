<script>
  import AssetDetail from "./AssetDetail.svelte";

  export let pm, pmArr;
</script>

<div class="details">
  {#each pmArr as { assetType, asset }}
    <AssetDetail {assetType} {asset} {pm} />
  {/each}
</div>

<style>
  .details {
    min-width: 9.333vw;
    padding: 0 16px 14px 16px;
    height: calc(100vh - 40px);
    overflow-y: scroll;
  }

  .details > :global(.detail) {
    margin-top: 16px;
  }

  .details > :global(.detail:first-of-type) {
    margin-top: 14px;
  }

  @media (min-width: 1667px) {
    .details {
      width: calc((100vw - 3 * 400px) / 3);
    }
  }
</style>
