<script>
  import polyphaser from "../helpers/polyphaser.js";
  import DetailsPanel from "./DetailsPanel.svelte";
  import assetPaths from "../helpers/asset-paths.js";
  export let permutationIndex;

  let pm,
    paths,
    pmArr,
    inputElement,
    inputContent,
    visualization,
    permutationIndexPerc;

  let visualizationXWidth = null;

  $: {
    pm = polyphaser.getPermutation(permutationIndex);
    paths = assetPaths(pm);

    permutationIndexPerc =
      permutationIndex / polyphaser.calcPossibilitySpaceSize();

    pmArr = [];

    for (let [key, value] of Object.entries(pm)) {
      pmArr.push({ assetType: key, asset: value });
    }
  }

  $: {
    if (inputElement) {
      inputElement.textContent = permutationIndex;
    }
  }

  $: {
    if (visualization) {
      visualizationXWidth = visualization.offsetWidth - 2;
    }
  }

  function shouldRenderImg(assetType, pm) {
    if (assetType === "beard" && pm.sex === "female") {
      return false;
    }

    if (pm[assetType] === null) {
      return false;
    }

    return true;
  }

  function handleInput(event) {
    inputContent = parseInt(event.target.textContent);
  }

  function handleFocus(event) {
    // TODO:
    // disable arrow key prevent default
  }

  function handleBlur(event) {
    // TODO:
    // if index is changed, add a record to history
    // enable arrow key prevent default

    if (inputContent !== undefined && inputContent.toString() !== "NaN") {
      permutationIndex = parseInt(inputContent);
      inputElement.textContent = parseInt(inputContent);
    } else {
      inputElement.textContent = permutationIndex;
    }
  }

  function handleKeyup(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      inputElement.blur();
    }
  }
</script>

<div class="permutation-display">
  <div class="columns">
    <div class="sprite-container">
      {#each paths as { assetType, path }}
        {#if shouldRenderImg(assetType, pm)}
          <img src={path} alt={pm[assetType]} />
        {/if}
      {/each}
    </div>

    <DetailsPanel {pm} {pmArr} />
  </div>

  <div class="index">
    <div class="index-heading">Index</div>
    <div
      class="index-value monospace"
      contenteditable="true"
      bind:this={inputElement}
      on:input={handleInput}
      on:focus={handleFocus}
      on:blur={handleBlur}
      on:keyup={handleKeyup}
    >
      {permutationIndex}
    </div>
  </div>

  <div bind:this={visualization} class="visualization">
    <svg
      ><rect
        x={visualizationXWidth * permutationIndexPerc}
        class="marker"
        width="2"
        height="4"
        fill="white"
      /></svg
    >
  </div>
</div>

<style>
  .permutation-display {
    display: flex;
    position: relative;
  }

  .columns {
    display: flex;
  }

  .index {
    position: absolute;
    padding: 12px 16px 8px 16px;
    background-color: rgba(33, 33, 33, 0.45);
  }

  .index-heading {
    font-weight: bold;
    opacity: 0.6;
  }

  .index-value {
    margin-top: 2px;
    font-size: 16px;
  }

  .visualization {
    background-color: rgba(255, 255, 255, 0.08);
    position: absolute;
    height: 4px;
    margin: 17px 16px 0 54px;
  }

  .visualization,
  svg {
    width: calc(24vw - 70px);
    max-width: calc(400px - 70px);
  }

  .marker {
    fill: rgba(255, 255, 255, 0.6);
    transition: x 0.1s ease;
  }

  .sprite-container {
    display: inline-block;
    width: 24vw;
    max-width: 400px;
    height: calc(100vh - 40px);
    background-color: #212121;
  }

  .sprite-container > img {
    position: absolute;
    width: 24vw;
    max-width: 400px;
  }
</style>
