import getAsset from "./get-asset.js";
import constructRefObj from "./contruct-ref-obj.js";
import getAssetTypes from "./get-asset-types.js";
import calcDivisors from "./calc-divisors";

const refObj = constructRefObj();
const assetTypesArr = getAssetTypes(refObj);
const divisors = calcDivisors(refObj, assetTypesArr);

function getPermutation(index) {
  let permutation = {};

  divisors.forEach((obj, i) => {
    let assetType = obj.assetType;

    if (i < 1) {
      permutation[assetType] = getAsset(refObj.accessories, index, false);
    } else {
      let prevObj = divisors[i - 1];
      let prevDivisor = prevObj.divisor;
      let prevLength = refObj[prevObj.assetType].length;

      permutation[assetType] = getAsset(
        refObj[assetType],
        index,
        prevDivisor,
        prevLength
      );
    }
  });

  return permutation;
}

function getRandomIndex() {
  return Math.floor(Math.random() * calcPossibilitySpaceSize());
}

function getRandomIndexLock(lockObj) {
  let randomIndex = getRandomIndex();
  let randomPermutation = getPermutation(randomIndex);

  if (randomPermutation[lockObj.assetType] !== lockObj.asset) {
    randomIndex = getRandomIndexLock(lockObj);
  }

  return randomIndex;
}

function calcPossibilitySpaceSize() {
  let assetArraysArr = [];
  for (const asset in refObj) {
    assetArraysArr.push(refObj[asset]);
  }

  return assetArraysArr
    .map((assetArr) => {
      return assetArr.length;
    })
    .reduce(function (accumulator, currentValue) {
      return accumulator * currentValue;
    });
}

export default {
  getPermutation,
  getRandomIndex,
  getRandomIndexLock,
  calcPossibilitySpaceSize,
};
