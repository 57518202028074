export default function calcDivisors(refObj, assetTypesArr) {
  let divisors = [];

  assetTypesArr.forEach((assetType, i) => {
    let divisor = 1;

    if (i > 0) {
      divisor =
        divisors[i - 1].divisor * refObj[divisors[i - 1].assetType].length;
    }

    divisors.push({ assetType, divisor });
  });

  return divisors;
}
