import assetIndex from "../../meta_data/asset-index.json";

export default function constructRefObj() {
  let refObj = assetIndex.male.bodyShape.bulky;

  let sexArr = [];
  for (const sex in assetIndex) {
    sexArr.push(sex);
  }
  refObj.sex = sexArr;

  let bodyShapeArr = [];
  sexArr.forEach((sex) => {
    for (const bodyShape in assetIndex[sex].bodyShape) {
      bodyShapeArr.push(bodyShape);
    }
  });
  refObj.bodyShape = bodyShapeArr;

  refObj.beard[refObj.beard.length] = null;
  refObj.hair[refObj.hair.length] = null;
  refObj.accessories[refObj.accessories.length] = null;
  refObj.scars[refObj.scars.length] = null;
  refObj.tattoos[refObj.tattoos.length] = null;

  return sortObjectAlphabetically(refObj);
}

function sortObjectAlphabetically(obj) {
  let sortedObj = {};
  Object.keys(obj)
    .sort()
    .forEach((key) => {
      sortedObj[key] = obj[key];
    });
  return sortedObj;
}
