export default function getRandomComparison(possibilitySpaceSize) {
  let demographics = [
    { country: "Denmark", population: 5806000 },
    { country: "Hungary", population: 9730000 },
    { country: "Germany", population: 83190556 },
    { country: "the Netherlands", population: 17474677 },
    { country: "the USA", population: 331449281 },
    { country: "Poland", population: 38268000 },
    { country: "Italy", population: 60317116 },
    { country: "Spain", population: 47450795 },
    { country: "France", population: 67413000 },
    { country: "Portugal", population: 10298252 },
    { country: "Russia", population: 143759445 },
    { country: "Japan", population: 125470000 },
  ];

  let randomDemographic =
    demographics[Math.floor(Math.random() * demographics.length)];

  let comparison = (
    possibilitySpaceSize / randomDemographic.population
  ).toFixed(1);

  return `${comparison} × the population of ${randomDemographic.country}`;
}
